@import "../../GlobalVriables.scss";

.box {
  height: 92%;
  box-shadow: 0px 3px 6px $box-shadow-color;
  background-color: $background-color;
  position: fixed;
  width: 23.5%;
  top: 3.8vw;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
}

.listitem {
  margin-top: 0 !important;
  padding-top: 0;
}

.filterlist {
  list-style: none;
}

.reset-icon {
  height: 1.25vw;
  width: 1.25vw;
  cursor: pointer;
}

.filters-title {
  font-size: $font-size-18px;
  width: 93%;
  margin-left: 0.3vw;
}

.filters {
  padding: 1.2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facet-settings {
  height: 3.64vw;
  background: $primary-color;
  box-shadow: 0px 3px 6px #00000029;
  color: $background-color;
}

.chip {
  margin: 0.25em;
}

.MuiSvgIcon-root {
  color: $primary-color !important;
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.MuiListItem-dense {
  padding: 0;
}

.MuiCollapse-wrapperInner {
  background-color: $tag-background-color;
}

.MuiListItem-button {
  padding-left: 1.5vw !important;
}

.MuiList-root {
  margin-right: 0 !important;
  &::-webkit-scrollbar {
    width: 0.5vw;
    height: 0.5vw;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
}