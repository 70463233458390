.app {
  background-color: #fff;
  padding: 0;
  min-width: 375px;
  font-family: Helvetica;
}

.main--home {}

.main--details {
  min-height: 40em;
}