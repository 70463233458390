@import "../../GlobalVriables.scss";

.results-info {
  margin: 1vw;
  color: $grey-1;
  font-size: $font-size-18px;
}

.results {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-left: 0em;
  margin-right: 0em;
}

.results .result {
  width: 96.7%;
}
