@import "../../GlobalVriables.scss";

.header {
  background: $background-color;
  box-shadow: 0px 2px 6px $box-shadow-color;
  color: $text-color-black;
  min-height: 3.64vw;
  padding-left: 1.5vw;  
  position: sticky;
  top: 0;
  z-index: 10;
  // width: 100vw;
}

.navbar {
  padding: 0 !important;
  flex-wrap: nowrap !important;
}

.navbar-nav {
  flex-direction: row !important;
  .MuiFormControl-root {
    margin-top: 2vw !important;
    width: 1vw !important;
    .MuiInput-underline {
      border-bottom: 0;
      &::after {
        border-bottom: 0;
      }
      &:hover {
        border-bottom: 0 transparent;
      }
      &::before {
        border-bottom: 0;
      }
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiPopover-paper {
      margin-top: 3vw;
    }
  }
}

.collapse {
  min-height: 3.64vw;
  // margin-left: 63vw;
  justify-content: flex-end;
}

.navbar-logo {
  width: 2.08vw;
  height: 2.08vw;
  border-radius: 100%;
}

.petrofac-logo-header {
  height: 2vw;
  cursor: pointer;
}

.petrofac-logo-wrapper {
  height: 3vw;
  // width: 23.2%;
  display: flex;
  align-items: center;
}

.nav-title {
  color: $text-color-black;
  padding-bottom: 0;
}

.nav-link {
  color: $text-color-black;
  font-size: $font-size-3;
}

.nav-link:hover {
  color: $text-color-black;
}

.nav-logout {
  text-decoration: underline;
  cursor: pointer;
  font-size: $font-size-1;
}

.logout-wrapper {
  border-left: 1px solid;
  width: 8.5vw;
}

.logout-icon {
  margin-right: 1vw;
  height: 1.5vw;
  margin-left: 1vw;
}

.auth-link {
  color: $text-color-black;
}

.auth-link:hover {
  color: $text-color-black;
}

.userName {
  font-size: $font-size-16px;
  width: 8vw;
  margin: unset;
  max-height: 3vw;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0.67vw;
}

.nav-user {
  display: flex;
  width: 9vw !important;
  align-items: center;
}

.logout-text {
  font-size: $font-size-16px;
  display: contents;
  margin-bottom: 0;
}

.help {
  display: flex;
    flex-direction: column;
    font-size: 0.83vw;
    align-items: center;
    color: #000;
    text-decoration: none;
    margin-right: 1vw;
    padding-right: 1vw;
    &:hover {
      color: #000;
      text-decoration: none;
    }
    svg {
      width: 2vw !important;
      height: 2vw !important;
      color: #000 !important;
      .MuiSvgIcon-root { 
        color: #000 !important;
      }
    }
}

.timeout-session-popup {
  .modal-body {
    display: grid !important;
    height: 2vw !important;
    text-align: center;
    align-content: space-evenly;
    background-color: #f7f8fa;
    width:35vw !important;
    // padding: 0.41vw;
    // border-radius: 5px;
    //     display: flex;
    //     justify-content: center;
  }
  .modal-content {
    border-top: 0.625em solid #0c629b !important;
    width:35vw !important;
    left: 31vw !important;
  }
  .modal-content label {
    font-weight: normal;
  }
}

// .modal-content .MuiButton-root {
//   margin-left: 0.03vw;
// }

// .modal-content {
//   margin-left: 0.016vw;
// }
