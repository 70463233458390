@import "../../../GlobalVriables.scss";

.facet-checkbox {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-body {
  padding-left: 3%;
}

.facet-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MuiListItem-button {
  border-bottom: 1px solid #00000034;
}

.MuiListItem-root {
  padding-left: 1.5vw !important;
  height: 3vw;
}

.MuiTypography-root {
  font-size: $font-size-18px !important;
  font-family: Helvetica !important;
}

.MuiListItem-dense {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiListItemText-root {
  color: $text-color-black;
}

.MuiListItemText-dense {
  // text-transform: uppercase;
}

.MuiIconButton-label {
  height: 0vw;
}
