
@import './../../GlobalVriables.scss';
.regex-page-container {
    .next-btn, .back-btn {
        width: 8.4vw;
        height: 2.29vw;
        font-family: Helvetica;
        font-size: 0.9vw;
        background: #0c629b;
        border: 0.05vw solid #0c629b;
        border-radius: 0.208vw;
        color: #ffffff;
        text-transform: capitalize;
    }

    .worspace-titlebar {
        display: flex;
        padding: 1.5vw;
        flex-direction: row;
        justify-content: space-between;
    }
    .workspace-data-table-wrapper {
        width: 100vw;
        max-height: 75vh;
        overflow: auto;
        .table {
            background-color: $background-color;
            thead {
                background-color: #E8EAEB;
                text-align: center;
                height: 4vw !important;
                border-top: 0.3vw solid #0c629b;
                th {
                    font-size: 1vw;
                    text-align: left;
                    padding-left: 4vw;
                    vertical-align: middle;
                    border-left: 0px;
                    border-right: 0px;
                }
                
            }
            tbody {
                text-align: center;
                font: normal normal normal 0.729vw Helvetica;
                letter-spacing: 0.27px;
            }
            tr {
                height: 3vw !important;
            }
            td {
                vertical-align: middle;
                font-size: 1vw;
                text-align: left;
                padding-left: 4vw;
                vertical-align: middle;
                border-left: 0px;
                border-right: 0px;
                max-width: 54vw;
            }            
        }
    }
}
.MuiDialogTitle-root {
    background: #0C629B;
    color: #FFFFFF;
    padding: 8px 12px !important;
    .MuiTypography-h6 {
        color: rgb(255, 255, 255);
        font-size: 1.2vw !important;
        font-family: "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.6;
    }
}

.MuiDialogContent-root {
    padding: 8px 13px !important;
}

textarea {
    overflow-y: scroll !important;
    &::-webkit-scrollbar {
        width: 1vw;
        height: 1vw;
      }
      &::-webkit-scrollbar-track {
        background: #e8eaeb;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(78, 77, 77);
        border-radius: 20px;
      }
}

.delete-confirm {
    .MuiDialog-paper {
        width: 20vw;
        height: auto;
        background-color: #f7f8fa;
    }
    .MuiDialogContent-root {
        display: flex;
        padding: 8px 9px;
    }
    
    .MuiSvgIcon-root {
        color: unset !important;
        width: unset !important;
        height: unset !important;

      }
}

