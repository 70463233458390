@import "../../GlobalVriables.scss";

div.container a.input-group-btn {
  font-size: $font-size;
}

.suggestions {
  display: flex;
  width: unset;
  z-index: 1;
  flex-direction: row;
  margin: 0 auto;
  .MuiFormHelperText-root.Mui-error {
    padding: 1%;
    font-size: $font-size-20px;
  }
}

.input-group {
  flex-wrap: nowrap;
  flex-direction: column;
}

.search-button {
  background-color: $text-color-black;
  border-radius: 100%;
  margin-top: -0.4vw;
  height: 3.85vw;
  width: 3.85vw;
  border: transparent;
}

.search-icon {
  height: 1.56vw;
  width: 1.56vw;
  margin-bottom: 0.2em;
}

.input-group-btn {
  z-index: 9;
  margin-left: -3vw;
  // margin-left: 59vw;
}



.MuiTooltip-tooltip {
  background-color: $text-color-black !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $text-color-black;
  font-size: $font-size-14px !important;
}

.MuiTooltip-arrow {
  padding-top: -1vw !important;
  color: $text-color-black !important;
}

.MuiTooltip-popper {
  margin-top: -1vw !important;
}

// .PrivateSwitchBase-root-1, .PrivateSwitchBase-root-12 {padding: 0 !important;}

.ExactLabel {
  span {
    padding: 0 !important;
  }
}