@import "../../../GlobalVriables.scss";

.suggestion-item:hover {
  background-color: #e9e9e9;
  cursor: pointer;
}

.suggestion-item {
  font-size: $font-size-24px;
}

.suggestions-box {
  background-color: $background-color;
  padding-left: 1vw;
  width: 61vw;
  max-width: 1192px;
  padding-right: 1vw;
  border-radius: 1vw;
  margin: 0 auto;
}
