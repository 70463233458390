@import "../../GlobalVriables.scss";

.main--details {
  min-height: auto !important;
  display: flex;
  height: 88vh;
}

.image {
  width: 10em;
  height: auto;
}

.result-container {
  min-width: 75%;
  max-width: 75%;
}

.no-data {
  font-size: $font-size-18px;
  color: #53565a;
}

.transcript {
 font-size: $font-size-18px !important;
}

.modal-dialog {
  margin: 1vw;
}

.modal-content {
  background-color: transparent !important;
  border: 0;
}

.modal-header {
  background-color: $primary-color;
  color: $background-color;
  padding: 0.5rem;
  font-weight: bold;
  border-bottom: 0;
}

.card {
  word-wrap: normal;
  border: 0;
}

.card-header {
  background-color: $background-color;
  box-shadow: 0px 3px 6px #00000024;
  width: 98vw;
  padding: 0 !important;
  border-radius: 0 !important;
}

.modal-title {
  font-size: $font-size-20px;
  font-family: $font-family;
  margin-left: 1%;
  // text-transform: capitalize;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link.active {
  border-color: $background-color;
  border-bottom: 2px solid $primary-color;
  color: $text-color-black;
  outline: transparent;
  font-size: $font-size-1;
  margin-left: 1.5vw;
  font-weight: bold;
  &:hover {
    font-weight: bold;
    border-bottom: 2px solid $primary-color;
  }
}

.nav-tabs .nav-link {
  color: $text-color-black;
  background-color: $background-color;
  font-family: $font-family;
  font-size: $font-size-1;
  margin-left: 1.5vw;
}

.nav-tabs .nav-link:focus {
  border-color: transparent;
  border-bottom: 2px solid $primary-color;
  outline: transparent;
  margin-left: 1.5vw;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
  outline: transparent;
  font-weight: bold;
  margin-left: 1.5vw;
}

button.close {
  outline: transparent !important;
}

.close {
  color: $background-color;
  opacity: 1;
  text-shadow: 0 0 0 $background-color;
  font-size: 2vw;
  font-weight: normal;
  margin: -2vw 0 -2vw -2vw !important;
  outline: transparent;
  padding: 1.5vw 0.5vw 1vw 1vw !important;
  height: 18px;
}

button {
  outline: transparent !important;
}

.close:hover {
  color: $background-color;
}

.result-container {
  .card-body {
    height: 3em;
    font-family: $font-family;
    white-space: pre-wrap;
    overflow-y: scroll;
    overflow-x: scroll;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    margin-top: 1vh;
    // font-size: $font-size-1;
    &::-webkit-scrollbar {
      width: 0.5vw;
      height: 0.5vw;
    }
    &::-webkit-scrollbar-track {
      background: #e8eaeb;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(78, 77, 77);
      border-radius: 20px;
    }
  }
}

.searchbtn {
  z-index: 9;
  margin-left: -3vw;
  .searchButton {
    background-color: $text-color-black;
    border-radius: 100%;
    height: 3vw;
    width: 3vw;
    border: transparent;
  }
  .searchIcon {
    height: 1.2vw;
    width: auto;
    margin-bottom: 0.3vw;
  }
}

.searchbar-modal {
  display: flex;
  margin: 1.5vw;
  margin-bottom: 1vw;
  width: 21.5vw;
  margin-top: 4.5vw;
  .form-control {
    background: $background-color;
    box-shadow: 0px 3px 10px $box-shadow-color;
    border: 1px solid $border-color;
    border-radius: 100px;
    opacity: 1;
    padding: 0.3vw 0 0 1vw;
    font-size: $font-size-24px;
    height: 3vw;
    width: 36vw;
  }
  .MuiInputBase-input {
    font-size: $font-size-16px;
    padding: 0.6vw;
    margin-left: -0.5vw;
  }
}

.search-container {
  background: $tag-background-color 0% 0% no-repeat padding-box;
  min-width: 25%;
  max-width: 25%;
  border-radius: 0.3vw;
  .occurences-title {
    display: flex;
    color: $text-color-black;
    font-size: $font-size-20px;
    font-weight: 600;
    font-family: $font-family;
    margin-bottom: 0.5vw;
    margin-left: 1.5vw;
    .occurences {
      font-weight: normal;
    }
  }
}

.search-text-bar {
  height: 2vw;
  width: 40vw;
  background: $background-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow-color;
  border-radius: 100px;
  opacity: 1;
  border: transparent;
  outline: transparent;
  padding-left: 3%;
  font-size: $font-size-20px;
  font-family: $font-family;
}

.YourHighlightClass, .YourHighlightClass1 {
  background-color: #fff72e;
  color: $text-color-black;
}

.content-highlighter {
  justify-content: flex-start;
  margin: 1.5em;
  height: 20vw;
  overflow-y: scroll;
  font-family: Helvetica;
  font-size: $font-size-18px;
  color: #53565a;
  width: 92%;
  text-align: justify;
  padding-right: 0.5vw;
  display: flex;
  flex-direction: column;
  .transcript-title {
    color: $text-color-black;
    font-size: $font-size-20px;
    font-weight: 600;
    font-family: $font-family;
    margin-bottom: 0.5vw;
  }
  .paraChunk {
    border: 0.15vw solid lightgray;
    min-height: 6vw !important;
    max-height: 6vw !important;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
  }
  &::-webkit-scrollbar {
    width: 0.5vw;
    height: 0.5vw;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
}

.downloadIcon {
  cursor: pointer;
  width: 1.2vw;
  z-index: 100;
  margin-bottom: 1vh;
}

#react-doc-viewer {
  min-height: 70vh;
  min-width: 70vw;
  padding-right: 2vw;
  background-color: $background-color;
  overflow: unset;
  #proxy-renderer {
    background-color: $background-color;
    overflow: unset;
  }
  // #pdf-controls {
  //   justify-content: space-evenly;
  // }
  #pdf-download {
    display: none;
  }
  #image-renderer {
    background: transparent;
  }
  #file-name, #no-renderer-download, #no-renderer {
    display: none;
  }
  .react-pdf__message, .react-pdf__message--error {
    display: none;
  }
  #header-bar {
    margin-top: -3vw;
  }
  #msdoc-renderer, #pdf-renderer {
    z-index: 100;
    overflow: unset;
    #pdf-controls {
      width: 67vw;
    }
  }
}

.errorMEssage {
    font-size: $font-size-18px;
}

.keyphrases-title {
  font-size: $font-size-20px;
  margin-left: 1.5vw;
  font-weight: 600;
  font-family: $font-family;
}

.see-more-tags-preview {
  margin-left: 0;
  color: $primary-color;
  text-decoration: underline;
  margin-top: 0.8%;
  cursor: pointer;
  font-size: $font-size-20px;
}

.taglist-details-page {
  display: flex;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 92%;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: 6vw;
  margin-left: 1.5vw;
  font-size: $font-size-16px;
  font-family: $font-family;
  margin-top: 0.3vw;
  &::-webkit-scrollbar {
    width: 0.5vw;
    height: 0.5vw;
  }
  &::-webkit-scrollbar-track {
    background: #e8eaeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
  }
  .tag {
    background: $background-color 0% 0% no-repeat padding-box;
    border-radius: 100px;
    height: 2vw;
    display: inline-flex;
    outline: none;
    margin: 0 0.5vw 0.5vw 0;
    font-size: $font-size-16px;
    align-items: center;
    white-space: nowrap;
    border-radius: 1vw;
    justify-content: center;
    border: 1px solid #e5e5e6;
  }
  .tagName {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    font-family: Helvetica;
  }
}

.annotationLayer {
  display: none;
}

.MuiTableContainer-root {
  &::-webkit-scrollbar {
      width: 0.5vw;
      height: 0.5vw;
    }
    &::-webkit-scrollbar-track {
      background: #e8eaeb;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #808080;
      border-radius: 20px;
    } 
}

.MuiTableCell-head {
  background-color: $primary-color !important;
}

td {
  overflow-wrap: anywhere;
}

th {
  vertical-align: baseline !important;
}

.MuiTableCell-root {
  font-size: $font-size-18px !important;
  padding: 0.5vw !important;
}

.viewer-container {
  display: flex;
  flex-direction: column;
}

.download-file {
  position: relative;
  #file-name {
    text-indent: -99999px;
  }
  #no-renderer {
    font-family: $font-family;
    font-size: $font-size-18px;
  }
  #no-renderer-download {
    font-family: $font-family;
  }
  .errorMEssage {
    position: absolute;
    top: 12vw;
    left: 0;
    right: 0;
  }
}