@import "../../GlobalVriables.scss";

.home-page {
  .petrofac-logo-wrapper {
    background-color: $background-color;
    height: 3.64vw;
  }
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: auto;
}

.home-search {
  margin: 18.28vw 18.9vw 30.72vw 18.95vw;
  max-width: 62.13vw;
  display: block;
}

.home-searchbar {
  // display: flex;
  .form-control {
    background: $background-color;
    box-shadow: 0px 3px 10px $box-shadow-color;
    border: 1px solid $border-color;
    border-radius: 100px;
    opacity: 1;
    padding: 0.5vw 0 0 1vw;
    font-size: $font-size-24px;
    height: 3.33vw;
    width: 62.13vw;
    max-width: 1192px;
  }
  .MuiFormHelperText-root.Mui-error {
    color: white;
    font-size: $font-size-20px;
    font-family: $font-family;
    font-weight: 600;
  }
  .MuiInputBase-input {
    font-size: $font-size-1;
    padding: 0.5vw;
    width: 84%;
    // border: 1px solid rebeccapurple;
  }
}

.regex {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  .regex-btn {
    color: white;
    border: 0.1vw solid;
    border-radius: 50%;
    text-transform: capitalize;
  }
}